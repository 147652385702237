<template>

  <b-card
    no-body
    class="p-1"
  >
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
        dir="rtl"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="isActive=true"
        >
          <feather-icon
            size="16"
            icon="FilterIcon"
          />
          فیلتر
        </b-button>
        <!--        <b-button-->
        <!--          v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--          variant="info"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            size="17"-->
        <!--            icon="FilePlusIcon"-->
        <!--          />-->
        <!--          افزودن سفارش-->
        <!--        </b-button>-->
      </div>

      <b-modal v-if="showDetails" />

      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="orders"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          no-local-sort
          style="white-space: nowrap; min-height : 235px"
          @sort-changed="sort($event)"
        >
          <template #cell(type)="data">
            <b-badge
              pill
              :variant="'light-'+typeVariant(data.item.type)"
              class="text-capitalize"
            >
              {{ typeLabel(data.item.type) + ' ' + data.item.crypto_persian_name}}
            </b-badge>
          </template>

          <template #cell(pay_method)="data">
            <b-badge
              pill
              :variant="'light-'+orderPayMethodVariant(data.item.pay_method)"
              class="text-capitalize"
            >
              {{ orderPayMethodLabel(data.item.pay_method) }}
            </b-badge>
          </template>

          <template #cell(crypto_image)="data">
            <b-avatar
              size="22"
              class="my-50"
              :src="data.item.crypto_image"
              :variant="`light-info`"
            />
            <!--            /-->
            <!--            <b-avatar-->
            <!--                size="22"-->
            <!--                class="my-50"-->
            <!--                :src="require(`@/assets/images/Coins/${data.item.marketType.includes('TOMAN') ? 'TOMAN' : 'TETHER'}.png`)"-->
            <!--                :variant="`light-info`"-->
            <!--            />-->
            <!--                - {{coinLabel(data.item.from)+' / '+coinLabel(data.item.to)}}-->
          </template>

          <template #cell(crypto_unit_fee)="data">
            <span dir="rtl">
              {{ Number(data.item.crypto_unit_fee).toLocaleString() + ' ' + 'تومان' }}
            </span>
          </template>

          <template #cell(amount)="data">
            <span dir="ltr">
              {{ (data.item.amount ? data.item.amount: 0) + ' ' + data.item.crypto_symbol }}
            </span>
          </template>

          <template #cell(cost)="data">
            <span dir="rtl">
              {{ Number(data.item.cost).toLocaleString() + ' ' + 'تومان' }}
            </span>
          </template>

          <template #cell(status)="data">
            <b-badge
              pill
              :variant="'light-'+orderStatusVariant(data.item.status)"
              class="text-capitalize"
            >
              {{ orderStatusLabel(data.item.status) }}
            </b-badge>
          </template>

          <template #cell(action)="{item,index}">
            <b-dropdown
              id="dropdown-offset"
              variant="link"
              no-caret
              offset="80px"
              :right="true"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="changeStatus(index)"
              >
                <feather-icon
                  class="text-danger"
                  icon="XCircleIcon"
                />
                <span class="text-danger align-middle ml-50">لغو سفارش</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="orderDetails(item.id)"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">جزییات سفارش</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </div>

      <!-- pagination -->
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
        dir="rtl"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="left"
          @input="getOrders(perPage, currentPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div>
          <label for="perpage">تعداد در صفحه</label>
          <v-select
            id="perpage"
            v-model="perPage"
            dir="rtl"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            style="min-width: 85px"
            @input="getOrders(perPage, currentPage)"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BOverlay,
  BTable,
  BBadge,
  BPagination,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Orders',
  components: {
    BCard,
    BButton,
    BOverlay,
    BTable,
    BBadge,
    BPagination,
    BAvatar,
    vSelect,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  data() {
    return {
      loading: false,
      showDetails: false,
      test: 0,
      pageLength: 3,
      dir: false,
      searchTerm: '',
      currentPage: 1,
      perPage: 10,
      rows: null,
      sortBy: '',
      isSortDirDesc: '',
      userData: [],
      isActive: false,
      isDetails: false,
      perPageOptions: [5, 10, 20, 50, 100],
      orders: [],
      columns: [
        {
          label: 'شماره سفارش',
          key: 'order_number',
          sortable: true,
          searchType: 'text',
        },
        {
          label: 'نام و نام خانوادگی',
          key: 'full_name',
          sortable: false,
          searchType: 'text',
        },
        {
          label: 'نوع سفارش',
          key: 'type',
          sortable: true,
          searchType: 'select',
          selectOptions: [
            { label: 'خرید', value: 'buy' },
            { label: 'فروش', value: 'sell' },
            { label: 'شارژ', value: 'charge' },
            { label: 'خرید محصول', value: 'product_pruchase' },
          ],
        },
        {
          label: 'روش پرداخت',
          key: 'pay_method',
          sortable: true,
          searchType: 'select',
          // selectOptions: [
          //   { label: 'کبف پول', value: 'wallet' },
          //   { label: 'فروش', value: 'sell' },
          //   { label: 'شارژ', value: 'charge' },
          //   { label: 'خرید محصول', value: 'product_pruchase' },
          // ],
        },
        {
          label: 'بازار',
          key: 'crypto_image',
          sortable: true,
          searchType: 'select',
          selectOptions: [
            { label: 'بیتکوین - تومان', value: 'BITCOIN_TOMAN' },
            { label: 'بیتکوین - تتر', value: 'BITCOIN_TETHER' },
            { label: 'اتریوم - تومان', value: 'ETHEREUM_TOMAN' },
            { label: 'اتریوم - تتر', value: 'ETHEREUM_TETHER' },
            { label: 'اتریوم کلاسیک - تومان', value: 'ETHEREUM_CLASSIC_TOMAN' },
            { label: 'اتریوم کلاسیک - تتر', value: 'ETHEREUM_CLASSIC_TETHER' },
            { label: 'لایت کوین - تومان', value: 'LITE_COIN_TOMAN' },
            { label: 'لایت کوین - تتر', value: 'LITE_COIN_TOMAN' },
            { label: 'بیتکوین کش - تومان', value: 'BITCOIN_CASH_TOMAN' },
            { label: 'بیتکوین کش - تتر', value: 'BITCOIN_CASH_TETHER' },
            { label: 'ریپل - تومان', value: 'RIPPLE_TOMAN' },
            { label: 'ریپل - تتر', value: 'RIPPLE_TETHER' },
            { label: 'بایننس کوین - تومان', value: 'BINANCE_COIN_TOMAN' },
            { label: 'بایننس کوین - تتر', value: 'BINANCE_TETHER' },
            { label: 'ایاس - تومان', value: 'EOS_TOMAN' },
            { label: 'ایاس - تتر', value: 'EOS_TETHER' },
            { label: 'استلار - تومان', value: 'STELLAR_TOMAN' },
            { label: 'استلار - تتر', value: 'STELLAR_TETHER' },
            { label: 'ترون - تومان', value: 'TRON_TOMAN' },
            { label: 'ترون - تتر', value: 'TRON_TETHER' },
            { label: 'دش - تومان', value: 'DASH_TOMAN' },
            { label: 'دش - تتر', value: 'DASH_TETHER' },
            { label: 'تتر - تومان', value: 'TOMAN_TETHER' },
            { label: 'تومان - تتر', value: 'TETHER_TOMAN' },
          ],
        },
        {
          label: 'مقدار',
          key: 'amount',
          sortable: true,
          searchType: 'number',
        },
        {
          label: 'قیمت واحد',
          key: 'crypto_unit_fee',
          sortable: true,
          searchType: 'number',
        },
        {
          label: 'ارزش کل',
          key: 'cost',
          sortable: false,
          // searchType: 'number'
        },
        // {
        //   label: 'انجام شده',
        //   key: 'executedAmount',
        //   sortable: true,
        //   searchType: 'number',
        // },
        // {
        //   label: 'انجام شده(درصد)',
        //   key: 'executedPercent',
        //   sortable: true,
        //   searchType: 'number',
        // },
        {
          label: 'وضعیت',
          key: 'status',
          sortable: true,
          searchType: 'select',
          selectOptions: [
            { label: 'تایید شده', value: 'confirm' },
            { label: 'رد شده', value: 'reject' },
            { label: 'در حال بررسی', value: 'processing' },
            { label: 'لغو شده کاربر', value: 'CANCELLED_BY_USER' },
            { label: 'لغو شده مدیر', value: 'CANCELLED_BY_ADMIN' },
          ],
        },
        {
          label: 'تاریخ ایجاد',
          key: 'jalali_created_at',
          sortable: true,
          searchType: 'date',
        },
        // {
        //   label: 'تاریخ انقضا',
        //   key: 'expiresAt',
        //   sortable: false,
        //   // searchType: 'date'
        // },
        {
          label: 'عملیات',
          key: 'action',
        },
      ],
    }
  },
  computed: {
    orderStatusVariant() {
      const a = {
        confirm: 'success',
        processing: 'warning',
        reject: 'danger',
      }
      return e => a[e]
    },
    orderStatusLabel() {
      const a = {
        confirm: 'تایید شده',
        processing: 'در حال بررسی',
        reject: 'رد شده',
      }
      return e => a[e]
    },
    typeVariant() {
      const a = {
        buy: 'success',
        sell: 'danger',
        charge: 'info',
        product_pruchase: 'primary',
      }
      return e => a[e]
    },
    orderPayMethodLabel() {
      const a = {
        wallet: 'انتقاب به کیف پول',
        bank_account: 'واریز به حساب بانکی',
        other_account: 'واریز به حساب دیگران',
      }

      return e => a[e]
    },
    orderPayMethodVariant() {
      const a = {
        wallet: 'primary',
        bank_account: 'info',
        other_account: 'dark',
      }
      return e => a[e]
    },
    typeLabel() {
      const a = {
        buy: 'خرید',
        sell: 'فروش',
        charge: 'شارژ',
        product_pruchase: 'خرید محصول',
      }
      return e => a[e]
    },
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    async getOrders(perPage, currentPage) {
      this.loading = true

      const queryParams = {
        per_page: perPage,
        page: currentPage,
      }

      const response = await this.$axios('/panel/order', { params: queryParams })
      this.orders = response.data.data
      this.rows = response.data.pagination.total
      this.perPage = response.data.pagination.per_page
      this.currentPage = response.data.pagination.current_page
      this.loading = false
    },
    orderDetails(id) {
      this.$router.push(`/order-details/${id}`)
    },
  },
}
</script>

<style scoped>

</style>
